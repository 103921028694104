import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import styles from "../../scss/components/EntradasLolla/_Main.module.scss"
import Logo from "../../images/Cattaneo/logo-bud.svg"
import logo from "../../images/TheBirraBehind/logobud.svg"
import lollapalooza from "../../images/EntradasLolla/lollapalooza2022.svg"
import lollaMobile from "../../images/EntradasLolla/lollaMobile.svg"
import lollaLogo from "../../images/EntradasLolla/lollalogo.svg"
import { IsMobileDevice } from "../../utils"

export default function Main() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.innerWidth <= 800 ? setIsMobile(true) : setIsMobile(false)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.textsContainer}>
        <img src={logo} className={styles.logo} />
        {/* <h1 className={styles.secondText}>BUDWEISER</h1> */}
        <h1 className={styles.secondText}>TE INVITA AL </h1>
        <h1 className={styles.secondText}>LOLLAPALOOZA 2022</h1>

        <h2 className={styles.subtitleText}>
          Completá con tus datos y <br /> participás por tickets para <br />{" "}
          vivir el mejor festival del año.
        </h2>
        {!isMobile ? (
          <h2 className={styles.subtitleText}>THE BIRRA BEHIND THE MUSIC</h2>
        ) : null}
      </div>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={lollaMobile} className={styles.ilus} />
          {/* <img src={lollaLogo} className={styles.ilus} /> */}
          <h2 className={styles.subtitleText}>THE BIRRA BEHIND THE MUSIC</h2>
        </div>
      ) : (
        <img src={lollapalooza} className={styles.ilus} />
      )}
    </div>
  )
}
