import React, { useState, useEffect } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import arrowDown from "./../images/icons/arrowDown.png"
import Cookies from "js-cookie"

//Components
import SEO from "../components/seo"
import Footer from "../components/Footer"
import AgeGate from "../components/AgeGate"

// From Entradas Lolla Component 
import Form from "../components/EntradasLolla/Form"
import Main from "../components/EntradasLolla/Main"
import Success from "../components/EntradasLolla/Success"

import styles from "./../scss/pages/_esport.module.scss"
import styles2 from "./../scss/components/EntradasLolla/_Main.module.scss"


const  EntradasLolla = ()=> {
  const [age, setAge] = useState(true)

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    if (cookie || local) setAge(false)
    window.scrollTo(0, 0)
  }, [])
  return !age ? (
    <Router>
      <Route exact path="/entradaslolla">
        <div styles={{ overflow: "hidden" }}>
          <SEO title="Entradas Lollapalooza 2022" />
          <Container fluid className="m-0 p-0">
            <div className={styles2.backgroundImg}>

            <section>
              <Main />
            </section>
            <Row className="d-flex justify-content-center m-0">
              <Col xs={"auto"}>
                <a
                  onClick={e =>
                    document
                      .querySelector("#FormSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src={arrowDown} className={styles.ArrowDownSection} />
                </a>
              </Col>
            </Row>
            <section id="FormSection">
              <Form />
            </section>
            </div>
            <Footer />
          </Container>
        </div>
      </Route>
      <Route path="/entradaslolla/success">
        <Success />
      </Route>
    </Router>
  ) : (
    <AgeGate path="/entradaslolla" />
  )
}


export default EntradasLolla;